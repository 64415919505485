/* Shoelace Components */
import { setDefaultAnimation } from '@shoelace-style/shoelace/dist/utilities/animation-registry.js'
import '@shoelace-style/shoelace/dist/components/alert/alert.js'
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js'
import '@shoelace-style/shoelace/dist/components/button/button.js'
import '@shoelace-style/shoelace/dist/components/button-group/button-group.js'
import '@shoelace-style/shoelace/dist/components/card/card.js'
import '@shoelace-style/shoelace/dist/components/details/details.js'
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js'
import '@shoelace-style/shoelace/dist/components/divider/divider.js'
import '@shoelace-style/shoelace/dist/components/drawer/drawer.js'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js'
import '@shoelace-style/shoelace/dist/components/icon/icon.js'
import '@shoelace-style/shoelace/dist/components/menu/menu.js'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js'
import '@shoelace-style/shoelace/dist/components/menu-label/menu-label.js'
import '@shoelace-style/shoelace/dist/components/popup/popup.js'
import '@shoelace-style/shoelace/dist/components/progress-bar/progress-bar.js'
import '@shoelace-style/shoelace/dist/components/skeleton/skeleton.js'
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js'
import '@shoelace-style/shoelace/dist/components/switch/switch.js'
import '@shoelace-style/shoelace/dist/components/tab/tab.js'
import '@shoelace-style/shoelace/dist/components/tab-group/tab-group.js'
import '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js'
import '@shoelace-style/shoelace/dist/components/range/range.js'
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js'

import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js'
setBasePath('/vendors/shoelace')

/* Utilities */
import '@zachleat/details-utils'
import '@github/relative-time-element'

// Disables selected animations in test to avoid flaky tests.
if (process.env.RAILS_ENV === 'test') {
  ;[
    'dialog.show',
    'dialog.hide',
    'dialog.overlay.show',
    'dialog.overlay.hide',
    'drawer.showTop',
    'drawer.showEnd',
    'drawer.showBottom',
    'drawer.showStart',
    'drawer.hideTop',
    'drawer.hideEnd',
    'drawer.hideBottom',
    'drawer.hideStart',
    'drawer.overlay.show',
    'drawer.overlay.hide',
  ].forEach((animation) => {
    setDefaultAnimation(animation, null)
  })
}
